
import { Component, Prop, Vue } from 'vue-property-decorator';
import KpiWidget from '../common/KpiWidget.vue';
import { NumberOfBendsDataPerDevice } from '@/models/Charts/numberOfBendsKpiWidgetGenerator';
import { ChartGenerator } from '@/models/Charts/abstract/chartGenerator';
import WidgetDefinition from '@/models/Charts/widgetDefinition';

/**
 * Actually 'Bend Count' widget.
 */
@Component({
  components: {
    KpiWidget,
  },
})
export default class NumberOfBendsKpiWidget extends Vue {
  @Prop({ required: true })
  private data!: NumberOfBendsDataPerDevice[] | null;

  @Prop({ required: true })
  private generator!: ChartGenerator<NumberOfBendsDataPerDevice>;

  @Prop({ required: true })
  private widgetDefinition!: WidgetDefinition;

  @Prop({ required: true, type: Boolean })
  private loading!: boolean;

  @Prop({ required: true, type: Boolean })
  private isFetchingForTheFirstTime!: boolean;

  private mappingFunction(entry: NumberOfBendsDataPerDevice) {
    return entry.bends;
  }
}
